import Image from 'next/image';
import Link from 'next/link';
import Config from '../config/Config';
import { useFeatureHub } from '../providers/FeatureHub';
import { ROLES, useAuth } from '../providers/Auth';
import { useApp } from '../providers/App';
import styles from '../styles/Home.module.css';
import ModalStatus from '../const/modal';

const navItems = [
  {
    title: 'Dashboard',
    href: Config.APP_URL || Config.WORDPRESS_URL,
    isVisible: true,
  },
  {
    title: 'Releases',
    href: '/releases',
    isVisible: true,
  },
  {
    title: 'News',
    href: `${Config.WORDPRESS_URL}/news`,
    isVisible: true,
  },
  {
    title: 'Podcasts',
    href: `${Config.WORDPRESS_URL}/podcasts/`,
    isVisible: true,
  },
  {
    title: 'Events',
    href: `${Config.WORDPRESS_URL}/events/`,
    isVisible: false,
  },
];

export default function Footer() {
  const { features } = useFeatureHub();
  const { setGlobalModalStatus } = useApp();
  const { hasRole } = useAuth();
  const { showReleases, socialLinks } = features;

  return (
    <footer data-testid="footer" className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={styles.footerNavLogo}>
          <div className={styles.footerLogo}>
            <Link href={Config.APP_URL || Config.WORDPRESS_URL}>
              <a className="flex">
                <Image src="/ss_logo.svg" alt="SoleSavy Logo" width={90} height={32} />
              </a>
            </Link>
          </div>
          <>
            <ul className={styles.footerNav}>
              {navItems.map(
                (navItem) =>
                  (navItem.title === 'Releases'
                    ? showReleases && hasRole(ROLES.calendar)
                    : navItem.isVisible) && (
                    <li key={navItem.title}>
                      <Link href={navItem.href}>
                        <a tabIndex={0} href={navItem.href}>
                          {navItem.title}
                        </a>
                      </Link>
                    </li>
                  )
              )}
              <li>
                <a
                  href={hasRole(ROLES.education) ? Config.EDUCATION_CENTER : '#'}
                  target="_blank"
                  onClick={(e) => {
                    if (!hasRole(ROLES.education)) {
                      e.preventDefault();
                      setGlobalModalStatus(ModalStatus.Upgrade);
                    }
                  }}
                  data-testid="education-center"
                  rel="noreferrer"
                >
                  Education Center
                </a>
              </li>
            </ul>
          </>
        </div>
        <div className={styles.footerSocial}>
          <span>Follow us:</span>
          <>
            {(socialLinks || []).map((socialLink) => (
              <span key={socialLink.name}>
                <a href={socialLink.link}>
                  <Image
                    src={`/icons/${socialLink.name}.svg`}
                    alt={socialLink.text}
                    width={19}
                    height={26}
                  />
                </a>
              </span>
            ))}
          </>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.footerCopyright}>
          &copy; {new Date().getFullYear()} SoleSavy, All rights reserved.
        </div>
        <div className={styles.footerLinks}>
          <a href={`${Config.WORDPRESS_URL}/faq`}>FAQ</a>
          <a href={`${Config.WORDPRESS_URL}/contact`}>Contact</a>
          <a href={`${Config.WORDPRESS_URL}/privacy-policy/`}>Privacy</a>
          <a href={`${Config.WORDPRESS_URL}/tos/`}>Terms & Conditions</a>
        </div>
      </div>
    </footer>
  );
}
