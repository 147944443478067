import { useMemo, useState } from 'react';
import { useApp } from '../providers/App';
import { planTiers, getCurrencyForCountry, getFamilyForCountry } from '../const/plan';
import PlanCard from './Plan/PlanCard';
import styles from '../styles/ChangeYourPlan.module.css';
import { Price } from '../interfaces/plan';
import { useUser } from '../providers/Auth';
import PeriodToggle from './Plan/PeriodToggle';
import ProductFamily from '../const/product_family';

const ChangeYourPlan = ({
  priceId,
  onChoose,
  hideBasic,
  isLoading,
  isActive = true,
}: {
  priceId: string;
  onChoose: Function;
  hideBasic?: boolean;
  isLoading?: boolean;
  isActive?: boolean;
}) => {
  const { prices } = useApp();
  const user = useUser();
  const [currentPeriod, setPeriod] = useState<string>('year');
  const [currency, family] = useMemo(() => {
    console.log('priceId', priceId);
    console.log('is active', isActive);
    const price: any = priceId && prices.find((_price) => _price.id === priceId);
    // console.log('filtered price', price);
    // (!isActive && price?.family ===ProductFamily.GOOGLE_PLAY_STORE) ? ProductFamily.MEMBERSHIP : price?.family || getFamilyForCountry(user?.locationCountry),
    return [
      price?.currency || getCurrencyForCountry(user?.locationCountry),
      !isActive && price?.family === ProductFamily.GOOGLE_PLAY_STORE
        ? ProductFamily.MEMBERSHIP
        : price?.family || getFamilyForCountry(user?.locationCountry),
    ];
  }, [priceId, user, prices, isActive]);

  return prices.length > 0 ? (
    <div className="md:h-full flex flex-col md:items-center justify-between">
      <div className="flex flex-col items-center">
        <h1 className={`${styles.title} text-3xlg lg:text-4xlg pb-10`}>Choose a plan</h1>
        <PeriodToggle
          leftOption="monthly billing"
          rightOption="annual billing"
          toggleLeft={currentPeriod === 'month'}
          onChange={() => setPeriod(currentPeriod === 'year' ? 'month' : 'year')}
        />
      </div>
      <div className={styles.planCardGroup}>
        {planTiers.map((planTier, index) => {
          const priceOptions = prices.filter(
            (_price) =>
              (!_price.family || _price.family === family) &&
              (!_price.currency || currency === _price.currency) &&
              (!hideBasic || (hideBasic && _price.amount)) &&
              _price.plan.toLowerCase().includes(planTier)
          );
          const montlyAlternative = priceOptions?.find(
            (_price) => _price.period === 'month'
          ) as Price;
          const price = priceOptions?.find((_price) => _price.period === currentPeriod) as Price;

          if (!price) {
            return null;
          }
          return (
            <PlanCard
              price={{
                ...price,
                currency: price.currency || currency,
              }}
              chosen={isActive ? priceId === price.id : !price.amount} // if not active, default to basic/free
              montlyAlternative={montlyAlternative}
              onChoose={() => onChoose(price.id, price.amount)}
              tag={index === 0 ? 'most popular' : ''}
              isLoading={isLoading}
            />
          );
        })}
      </div>
      <div className="flex justify-center">
        <p className={styles.disclaimer}>Prices do not include taxes and fees where applicable.</p>
      </div>
    </div>
  ) : null;
};

ChangeYourPlan.defaultProps = {
  hideBasic: false,
  isLoading: false,
  isActive: true,
};

export default ChangeYourPlan;
