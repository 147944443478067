import { UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import DropDownField from './DropDownField';
import ChoiceField from './ChoiceField';
import { MenuOption } from '../../interfaces/dropdown';
import { SizeTypes, sneakerSizesByType, SupportedSneakerSizeMax } from '../../const/profile';
import styles from '../../styles/SettingsLayout.module.css';
import Message, { types as messageTypes } from '../Message';

const name = 'sneakerSize';

const getSizeType = (sizeType: string, country: string) =>
  sizeType || (country === 'GB' && 'uk') || 'us';

const getGenderedSizeType = (sizeType: string, gender: string) => {
  if (sizeType === 'us' && gender === 'male') {
    return 'us.m';
  }
  if (sizeType === 'us' && gender === 'female') {
    return 'us.w';
  }
  return sizeType;
};

const SneakerSizeField = ({
  form,
  showSizeTypes,
}: {
  form: UseFormReturn;
  showSizeTypes?: boolean;
}) => {
  const { getValues, setValue } = form;
  const [sneakerSize, rawSneakerSizeType, gender, country] = getValues([
    name,
    'sneakerSizeType',
    'gender',
    'locationCountry',
  ]);
  const sneakerSizeType = getSizeType(rawSneakerSizeType, country);
  const sneakerSizes = sneakerSizesByType(getGenderedSizeType(sneakerSizeType, gender));
  const sneakerSizeItem = (sneakerSize &&
    sneakerSizes.find(({ value }) => value === sneakerSize)) as undefined | MenuOption;

  useEffect(() => {
    if (rawSneakerSizeType !== sneakerSizeType) {
      setValue('sneakerSizeType', sneakerSizeType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawSneakerSizeType, sneakerSizeType]);

  return (
    <>
      {showSizeTypes && (
        <>
          <div className="flex gap-4">
            {SizeTypes.map((type) => (
              <ChoiceField form={form} name="sneakerSizeType" value={type.value as string}>
                {type.label}
              </ChoiceField>
            ))}
          </div>
          <div className="flex items-center gap-1">
            <span className={styles.dividerLine} />
            <span className={styles.overLineText}>what’s your true to size fit</span>
            <span className={styles.dividerLine} />
          </div>
        </>
      )}
      <DropDownField
        form={form}
        name={name}
        placeholder="Sneaker size"
        defaultSelectedOption={sneakerSizeItem}
        options={sneakerSizes}
      />
      {sneakerSizeItem && sneakerSize >= SupportedSneakerSizeMax && (
        <Message className="max-w-centerMessage" type={messageTypes.warning}>
          Support for shoe sizes&nbsp;
          <strong className="font-bold">
            {sneakerSizeType.toUpperCase()} {sneakerSizeItem.label}
          </strong>
          &nbsp; and above is limited. Continue at your own discretion.
        </Message>
      )}
    </>
  );
};

SneakerSizeField.defaultProps = {
  showSizeTypes: false,
};
export default SneakerSizeField;
