import sanityClient from '@sanity/client';
import * as Sentry from '@sentry/nextjs';
import moment from 'moment';

import Config from '../config/Config';
import ReleaseStatus, { BrandGroup } from '../const/release';

const sanityConfig = {
  projectId: Config.SANITY_PROJECT_ID,
  dataset: Config.SANITY_DATA_SET,
  apiVersion: Config.SANITY_API_VERSION,
  useCdn: true,
};

const client = sanityClient(
  Config.SANITY_API_TOKEN?.trim()
    ? {
        ...sanityConfig,
        token: Config.SANITY_API_TOKEN,
        useCdn: false, // recommended not to use cdn when there's a token
      }
    : sanityConfig
);

export interface ReleaseListParams {
  sku: string | undefined;
  dateStart: string | undefined;
  dateEnd: string | undefined;
  brand: string | undefined;
  offset: number | undefined;
  count: number | undefined;
  hasStrategy: boolean | undefined;
  hasOpenRaffles: boolean | undefined;
}

const SanityAPIService = () => ({
  brands: {
    list: async () => {
      const filters = ['_type == $type', '!(_id in path("drafts.**"))', 'group != $other'];
      const params = {
        type: 'brand',
        other: BrandGroup.Other,
      };
      return client
        .fetch(
          `*[${filters.join(' && ')}] | order(group,name) {
          ...,
          'id': _id,
          logo {
            ...,
            asset->{url},
          },
        }`,
          params
        )
        .then((brands) => ({ brands }))
        .catch((error) => {
          Sentry.captureException(error);
          return { brands: [], isError: true };
        });
    },
  },
  releases: {
    list: async ({
      sku,
      dateStart,
      dateEnd,
      brand,
      offset = 0,
      count = 10,
      hasStrategy = false,
      hasOpenRaffles = false,
    }: ReleaseListParams) => {
      const filters = ['_type == $type', '!(_id in path("drafts.**"))'];
      const raffleFilters = [`_type == 'raffle' && _id in ^.raffles[]._ref`];
      const postFilters = [];
      const params = {
        type: 'release',
        offset,
        count: count + offset,
        now: new Date().toISOString(),
      };

      if (hasStrategy) {
        filters.push(`count(strategy) > 0`);
        filters.push(`!(body[].children[].text match "${ReleaseStatus.TBA}")`);
      }

      if (hasOpenRaffles) {
        raffleFilters.push(`dateTime(startDate) <= dateTime($now)`);
        raffleFilters.push(`dateTime(endDate) >= dateTime($now)`);
        postFilters.push('[count(raffles) > 0]');
      }

      if (sku) {
        filters.push('(sku == $sku || _id == $sku)');
        Object.assign(params, {
          sku,
        });
      }
      if (dateStart) {
        filters.push('releaseDate >= $dateStart');
        Object.assign(params, {
          dateStart: moment(dateStart).startOf('day').add(300, 'minutes'), // from EST to UTC
        });
      }
      if (dateEnd) {
        filters.push('releaseDate <= $dateEnd');
        Object.assign(params, {
          dateEnd: moment(dateEnd).endOf('day').add(300, 'minutes'), // from EST to UTC
        });
      }
      if (brand) {
        const value = brand === 'Other' ? BrandGroup.Other : brand;

        filters.push('(brand == $val || brandRef->name == $val || brandRef->group == $val)');
        Object.assign(params, {
          val: value,
        });
      }

      return client
        .fetch(
          `*[(${filters.join(') && (')})] | order(releaseDate, title) {
          ...,
          'id': _id,
          image {
            ...,
            asset->{url},
          },
          brandRef->,
          retailers[] {
            ...,
            retailer->,
          },
          'raffles': *[ ${raffleFilters.join(' && ')} ] {
            ...,
            retailer->, 
          },
        }${postFilters.join()}[$offset...$count]`,
          params
        )
        .then((releases) => ({
          releases: releases.map((release: any) => {
            const newRelease = {
              retail: '',
              currency: '',
              ...release,
              brand: release.brandRef?.name || release.brand, // overwrite legacy field with referenced brand
              retailers: (release.retailers || []).map((retailer: any) => {
                const dateOverride = retailer.releaseDate;
                const newRetailer = {
                  // flatten referenced base retailer with overrides
                  ...retailer.retailer,
                  ...retailer,
                };

                if (!dateOverride) {
                  // no date override, use common time (if avail) with parent release's date
                  const time = retailer.retailer?.releaseTime?.split('-').shift();
                  if (time) {
                    const date = release.releaseDate?.split('T')[0];
                    // console.log('time', time, date, moment(date).isDST())
                    newRetailer.releaseDate = `${date}T${time}-${
                      moment(date).isDST() ? '0400' : '0500'
                    }`;
                    // console.log('release date', newRetailer.releaseDate)
                  }
                }

                if (!newRetailer.releaseDate) {
                  newRetailer.releaseDate = release.releaseDate;
                }
                delete newRetailer.retailer;
                return newRetailer;
              }),
              raffles: (release.raffles || []).map((raffle: any) => ({
                ...raffle,
                /* eslint-disable */
                id: raffle._key || raffle._id,
                /* eslint-enable */
                name: raffle?.retailer?.name || '', // replace deprecated name
              })),
            };
            return newRelease;
          }),
          sku,
        }))
        .catch((error) => {
          Sentry.captureException(error);
          return { releases: [], sku: undefined, isError: true };
        });
    },
  },
});

export default SanityAPIService;
