/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactNode, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Image from 'next/image';
import FocusTrap from 'focus-trap-react';
import styles from '../styles/SlackCommunity.module.css';

interface ModalProps {
  openModal: boolean;
  handleClose: any; // function to handle close
  children: ReactNode;
  overrideInternalModal?: boolean;
  persistent?: boolean;
  frameless?: boolean;
  fullScreen?: boolean;
}

export default function Modal({
  openModal,
  handleClose,
  children,
  overrideInternalModal,
  persistent,
  frameless,
  fullScreen,
}: ModalProps) {
  // due to it being SSR, this makes sure the component is safe to render
  const [isBrowser, setIsBrowser] = useState(false);
  const [isClose, setIsClose] = useState(true);
  const animateClose = () => {
    setIsClose(true);
    setTimeout(handleClose, 400);
  };

  useEffect(() => {
    setIsBrowser(true);
  }, []);
  useEffect(() => {
    setIsClose(!openModal);
  }, [openModal]);

  // If Esc is pressed on, the modal should be closed
  const handleKeyDown = (event: any) =>
    event.keyCode === 27 && !persistent ? animateClose() : null;

  const modal = openModal ? (
    <div id="modal" data-testid="modal" onKeyDown={handleKeyDown}>
      <FocusTrap
        focusTrapOptions={{
          onDeactivate: !persistent ? animateClose : undefined,
          fallbackFocus: '#backdrop-filter #modal-close-button', // to eliminate focus trap error: Your focus-trap needs to have at least one focusable element
        }}
      >
        <div
          id="backdrop-filter"
          data-testid="backdrop-filter"
          onClick={!persistent ? animateClose : undefined}
          className={`${styles.backdropFilter} ${!isClose ? styles.backdropFilterOpen : ''} ${
            fullScreen ? styles.fullScreen : ''
          }`}
        >
          {!persistent && (
            <button
              id="modal-close-button"
              type="button"
              className="fixed right-0 m-5 md:m-10 z-10 top-0"
              style={{ width: '28px', height: '28px' }}
            >
              {!fullScreen ? (
                <Image src="/icons/modal-close.svg" alt="exit" width="28" height="28" />
              ) : (
                <Image src="/icons/modal-close-black.svg" alt="exit" width="28" height="28" />
              )}
            </button>
          )}
          <div
            id="modal-content-wrapper"
            data-testid="modal-content-wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={`${
              fullScreen ? styles.fullScreenModalContent : styles.modalContentWrapper
            } ${
              !isClose && !fullScreen ? styles.modalContentOpen : styles.fullScreenModalContentOpen
            }`}
          >
            {/* content */}
            {overrideInternalModal ? (
              <div id="modal-overridden-content" data-testid="modal-overridden-content">
                {children}
              </div>
            ) : (
              <div
                id="modal-content"
                data-testid="modal-content"
                role="main"
                className={`${fullScreen ? styles.fullScreen : styles.modalContent}`}
              >
                {/* header */}
                {/* body */}
                {frameless ? (
                  children
                ) : (
                  <div
                    className={`relative flex-auto ${
                      fullScreen ? 'h-full px-3 sm:px-8 pb-0' : 'p-3 sm:p-8'
                    }`}
                  >
                    {children}
                  </div>
                )}
                {/* footer */}
              </div>
            )}
          </div>
        </div>
      </FocusTrap>
    </div>
  ) : null;

  if (isBrowser) {
    // - createPortal allows component to render outside the normal dom flow instead of rendering
    //   next to parent.
    // - '__next' is the root element ID of a NextJS app
    return createPortal(modal, document.getElementsByTagName('body')[0] as unknown as Element);
  }
  return null;
}

Modal.defaultProps = {
  overrideInternalModal: false,
  persistent: false,
  frameless: false,
  fullScreen: false,
};
