import styles from '../../styles/ChangeYourPlan.module.css';

interface PeriodToggleProps {
  toggleLeft?: boolean;
  leftOption: string;
  rightOption: string;
  onChange: Function;
}

const PeriodToggle = ({ toggleLeft, leftOption, rightOption, onChange }: PeriodToggleProps) => (
  <a
    href="#"
    onClick={(e) => {
      e.preventDefault();
      onChange();
    }}
    className="pb-20"
  >
    <div className="flex items-center gap-4">
      <div className={styles.toggleWrapper}>
        <div className={`${styles.toggleSelected} ${toggleLeft ? '' : 'translate-x-44'}`} />
        <div className={`${styles.periodOption} ${toggleLeft ? styles.selected : ''}`}>
          {leftOption}
        </div>
        <div className={`${styles.periodOption} ${toggleLeft ? '' : styles.selected}`}>
          {rightOption}
        </div>
      </div>
    </div>
  </a>
);

PeriodToggle.defaultProps = {
  toggleLeft: true,
};

export default PeriodToggle;
