import Image from 'next/dist/client/image';
import styles from '../../styles/OnboardingCard.module.css';
import { UpgradePlanBody } from './UpgradePlan';

const LegacyPlusUpgradePlanModal = () => (
  <div className={styles.upgradePlanModal}>
    <Image src="/images/upgrade-plan.svg" width={298} height={198} />
    <h4 data-testid="title" className="font-tommyBold text-2xl font-medium mt-8">
      Unlock the power of Solesavy
    </h4>
    <div className={`${styles.description} flex flex-col items-center`}>
      <UpgradePlanBody />
    </div>
  </div>
);

export default LegacyPlusUpgradePlanModal;
