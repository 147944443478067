import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import Modal from '../Modal';
import { useAuth, useUser, useIsBasic } from '../../providers/Auth';
import Error from '../Field/Error';
import ChargebeeAPIService from '../../services/Chargebee';
import ChangeYourPlan from '../ChangeYourPlan';
import { useApp } from '../../providers/App';
import ModalStatus from '../../const/modal';
import basicPlan from '../../const/plan';
import { useFeatureHub } from '../../providers/FeatureHub';

const chargebeeService: any = ChargebeeAPIService();

const modalName = 'upgrade';

const handleMessage = (e: MessageEvent) => {
  if (
    e?.data?.url &&
    e?.data?.key === 'cb.subscription.cancel_brightback.initiated' &&
    e?.origin?.includes('chargebee.com')
  ) {
    window.location.href = e.data.url;
  }
};

const UpgradeYourPlanModal = () => {
  const router = useRouter();
  const { globalModalStatus, setGlobalModalStatus } = useApp();
  const { checkToken } = useAuth();
  const user = useUser();
  const { features } = useFeatureHub();
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const isBasic = useIsBasic();

  const { id: checkoutId, state: checkoutState, modal } = router?.query || {};

  const getHostedPage = async (priceId?: string, url?: string) => {
    setIsLoading(true);
    console.log(`user: ${user}`, user);
    const result = await chargebeeService.hosted({
      user,
      priceId,
      url,
      currentSub: user?.subscription,
      mode: globalModalStatus === ModalStatus.Manage ? 'manage' : 'checkout',
    });
    setIsLoading(false);
    if (result?.checkout?.url) {
      sessionStorage.setItem('checkoutId', result.checkout.id);
      setCheckoutUrl(result.checkout.url);
    } else if (result?.manage?.access_url) {
      sessionStorage.setItem('checkoutId', result.manage.id);
      setCheckoutUrl(result.manage.access_url);
      // router.push(result.manage.access_url);
    } else if (result?.error) {
      setErrorMsg(result.error.error_msg);
      // TODO: handle error better?
    }
  };

  useEffect(() => {
    if (modal === modalName && checkoutState) {
      // chargebee reloaded the page
      checkToken(true); // force refresh token to pull updated permissions from FA
      if (checkoutId === sessionStorage.getItem('checkoutId') && checkoutState === 'succeeded') {
        toast.success('Payment successful', {
          autoClose: 10000,
          onClose: () => checkToken(true), // try again in case FA hasn't been updated yet on first try
        });
        sessionStorage.removeItem('checkoutId');
      } else if (checkoutState === 'refresh') {
        setTimeout(
          () => checkToken(true), // try again in case FA hasn't been updated yet on first try
          10000
        );
      }
      delete router.query.id;
      delete router.query.state;
      delete router.query.modal;
      router.push({
        query: {
          ...router.query,
          [modalName]: 'complete',
        },
      });
      setGlobalModalStatus(ModalStatus.Closed);
    } else if (globalModalStatus !== ModalStatus.Closed) {
      if (globalModalStatus === ModalStatus.Manage) {
        const returnUrl = new URL(window.location.href);
        returnUrl.searchParams.set('modal', modalName);
        returnUrl.searchParams.set('state', 'refresh');
        getHostedPage(user?.registeredPlan, returnUrl.toString());
      }
      delete router.query[modalName];
      router.push({
        query: {
          ...router.query,
          modal: modalName,
        },
      });
    } else {
      delete router.query.modal;
      router.push({
        query: router.query,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutId, checkoutState, globalModalStatus, modal, user?.registeredPlan]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage, true);
  }, []);

  const currentPriceId: string = useMemo(() => {
    const communityCode = user?.communityCode as string;
    let community;

    if (user?.subscription?.item?.item_price_id) {
      return user.subscription.item.item_price_id;
    }
    if (communityCode && features?.communities) {
      community = features.communities.find((comm) => comm.codePrice[communityCode]);
    }
    return community?.codePrice[communityCode] || basicPlan.id;
  }, [user?.subscription?.item?.item_price_id, user?.communityCode, features]);

  return (
    <Modal
      openModal={globalModalStatus !== ModalStatus.Closed}
      handleClose={() => {
        checkToken(true); // force refresh token to pull updated permissions from FA
        setTimeout(
          () => checkToken(true), // try again in case FA hasn't been updated yet on first try
          10000
        );
        setCheckoutUrl(null);
        setErrorMsg('');
        setGlobalModalStatus(ModalStatus.Closed);
        delete router.query.modal;
        delete router.query[modalName];
        router.push({
          query: router.query,
        });
      }}
      fullScreen={!errorMsg && !checkoutUrl && globalModalStatus === ModalStatus.Upgrade}
      frameless={!!checkoutUrl}
    >
      {errorMsg && <Error>{errorMsg}</Error>}
      {!errorMsg && !!checkoutUrl && (
        <iframe title="checkout" src={checkoutUrl} height={600} width={400} />
      )}
      {!errorMsg && !checkoutUrl && globalModalStatus === ModalStatus.Upgrade && (
        <ChangeYourPlan
          isLoading={isLoading}
          hideBasic={!isBasic}
          priceId={currentPriceId}
          isActive={!!user?.subscription?.isActive}
          onChoose={async (priceId: string) => getHostedPage(priceId)}
        />
        // <ChangeYourPlan
        //   isLoading={isLoading}
        //   hideBasic={!isBasic}
        //   priceId='mobile_plus_monthly_android-USD'
        //   isActive = {false}
        //   onChoose={async (priceId: string) => getHostedPage(priceId)}
        // />
      )}
    </Modal>
  );
};

export default UpgradeYourPlanModal;
