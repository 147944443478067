import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import InputIcon from '@material-tailwind/react/InputIcon';
import DropDownMenu from '../DropDownMenu';
import { MenuOption } from '../../interfaces/dropdown';
import { inputProps } from '../../const/profile';
import styles from '../../styles/SettingsLayout.module.css';

const DropDownField = ({
  form,
  name,
  placeholder,
  defaultSelectedOption,
  options,
  selectOption,
}: {
  form: UseFormReturn;
  name: string;
  placeholder: string;
  defaultSelectedOption?: MenuOption;
  options: Array<MenuOption>;
  selectOption?: Function;
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);
  const [popupsOpen, setPopupsOpen] = useState(false);

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = form;

  const fieldVal = getValues(name);

  const handleMenu = (newSelectedOption: MenuOption | undefined, index: number) => {
    setValue(name, newSelectedOption?.value || '', {
      shouldValidate: true,
      shouldDirty: true,
    });
    setSelectedOption(newSelectedOption);
    if (selectOption) {
      selectOption(newSelectedOption, index, name);
    }
  };

  useEffect(() => {
    if (defaultSelectedOption) {
      setSelectedOption(defaultSelectedOption);
    }
  }, [defaultSelectedOption]);

  useEffect(() => {
    if (!fieldVal) {
      setSelectedOption(undefined);
    }
  }, [options, fieldVal]);

  return (
    <div
      className={`w-full relative ${(errors as any)[name]?.message !== '' ? 'mb-4' : ''}`}
      key={name}
    >
      <Controller
        control={control}
        name={name}
        rules={{ required: `${placeholder} is required.` }}
        render={({ field: { ref, ...field } }) => (
          <div className={`${styles.field} ${styles.absoluteSpan}`}>
            <InputIcon
              {...inputProps(field)}
              value={selectedOption?.label || ''}
              placeholder={placeholder}
              error={(errors as any)[name]?.message}
              readOnly
              onFocus={() => setPopupsOpen(true)}
              onBlur={() => setTimeout(() => setPopupsOpen(false), 100)}
              iconFamily="material-icons"
              iconName="expand_more"
            />
          </div>
        )}
      />
      <div className="absolute w-full z-10">
        <DropDownMenu
          isShow={popupsOpen}
          showButton={false}
          menuItem={{
            name,
            options,
          }}
          selectOption={handleMenu}
        />
      </div>
    </div>
  );
};

DropDownField.defaultProps = {
  defaultSelectedOption: undefined,
  selectOption: () => {},
};

export default DropDownField;
