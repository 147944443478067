import { ReactNode } from 'react';
import Image from 'next/image';

const Error = ({ children, ...props }: { children: ReactNode | string }) => (
  <div className="text-red-500 pt-1 gap-1 flex items-center" {...props}>
    <Image src="/icons/error.svg" alt="error" width="16" height="16" />
    <span>{children}</span>
  </div>
);

export default Error;
