/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import ChoiceField from './ChoiceField';
import InfiniteLoader from '../InfiniteLoader';
import { Brand } from '../../interfaces/release';
import SanityAPIService from '../../services/Sanity';
import { isTesting } from '../../utils';
import styles from '../../styles/SettingsLayout.module.css';

const apiService: any = SanityAPIService();

const name = 'brands';

const TopBrandsField = ({ form }: { form: UseFormReturn }) => {
  const { watch, setValue } = form;
  const brands = watch(name);
  const [availableBrands, setBrands] = useState([]);
  const loaded = availableBrands?.length > 0;

  useEffect(() => {
    let promise: any;
    if (!isTesting()) {
      promise = apiService.brands.list();
      promise.then((result: any) => {
        if (result?.brands) {
          setBrands(result.brands);
        }
      });
    }
    return () => {
      if (promise?.abort) {
        promise.abort();
      }
    };
  }, []);
  return (
    <div className={`${styles.brandsWrapper} ${loaded ? styles.brandsLoaded : ''}`}>
      {availableBrands?.length ? (
        <div className="grid grid-cols-4 md:grid-cols-6 gap-4 mb-5">
          {availableBrands
            .filter((brand: Brand) => brand.logo)
            .map((brand: Brand) => {
              const checked = brands.includes(brand.id);
              return (
                <ChoiceField
                  form={form}
                  multiple
                  name={name}
                  value={brand.id}
                  checked={checked}
                  onClick={() =>
                    setValue(
                      name,
                      (checked
                        ? brands.filter((_brand: string) => _brand !== brand.id)
                        : brands.concat(brand.id)
                      ).filter((_brandId: string) =>
                        availableBrands.find((_brand: Brand) => _brand.id === _brandId)
                      ),
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                      }
                    )
                  }
                >
                  <img
                    key={brand.name}
                    src={brand.logo?.asset?.url}
                    alt={brand.logo?.alt || brand.name}
                  />
                </ChoiceField>
              );
            })}
        </div>
      ) : (
        <div className="text-center spy-2">
          <InfiniteLoader />
        </div>
      )}
    </div>
  );
};
export default TopBrandsField;
