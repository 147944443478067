import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import InputIcon from '@material-tailwind/react/InputIcon';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import styles from '../../styles/SettingsLayout.module.css';
import { inputProps, birthDateFormat, MinAge } from '../../const/profile';
import { parseDate } from '../../utils';

const name = 'birthDate';

const BirthdateField = ({ form }: { form: UseFormReturn }) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = form;
  const birthDate = getValues(name);
  const [popupsOpen, setPopupsOpen] = useState(false);
  useEffect(() => {
    const el = document.querySelector('body');
    const dismiss = (e: any) => {
      if (
        popupsOpen &&
        ((e.path &&
          !e.path.find((node: HTMLElement) => node?.classList?.contains('customDatePicker'))) ||
          (e.target && !e.target.className?.includes('datepicker')))
      ) {
        setPopupsOpen(false);
      }
      el?.removeEventListener('click', dismiss);
    };
    el?.addEventListener('click', dismiss);
    return () => {
      el?.removeEventListener('click', dismiss);
    };
  }, [popupsOpen]);

  return (
    <div className={`${styles.birthdaySection} mb-4 customDatePicker`}>
      <Controller
        control={control}
        rules={{ required: 'Birthday is required.' }}
        name={name}
        render={({ field: { ref, ...field } }) => (
          <div className={`${styles.field} ${styles.absoluteSpan}`}>
            <InputIcon
              {...inputProps(field)}
              placeholder="Birthday"
              readOnly
              onClick={() => setPopupsOpen(!popupsOpen)}
              error={errors?.birthDate?.message}
              iconFamily="material-icons"
              iconName="calendar_month"
            />
          </div>
        )}
      />
      {popupsOpen && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className="absolute right-0 top-16 z-10">
          <DatePicker
            minDate={new Date(moment().subtract(99, 'years').format('LL'))}
            maxDate={new Date(moment().subtract(MinAge, 'years').format('LL'))}
            selected={parseDate(birthDate, undefined, null) as Date | null}
            onChange={(date) => {
              setValue(name, moment(date).format(birthDateFormat), {
                shouldValidate: true,
                shouldDirty: true,
              });
              setPopupsOpen(false);
            }}
            dropdownMode="select"
            inline
            peekNextMonth
            showMonthDropdown
            showYearDropdown
          />
        </div>
      )}
    </div>
  );
};

export default BirthdateField;
