import Image from 'next/image';
import Link from 'next/link';
import styles from '../styles/Avatar.module.css';

type menuOffset = 'center' | 'right';
interface AvatarDropdownMenuProps {
  isVisible?: boolean;
  menuItems: {
    icon: string;
    text: string;
    link: string;
    width: number;
    height: number;
    target: string;
  }[];
  offset?: menuOffset;
}

export default function AvatarDropdownMenu({
  menuItems,
  isVisible,
  offset,
}: AvatarDropdownMenuProps) {
  const showMenu = isVisible ? '' : ' hidden ';

  const getOffset = () => (offset === 'center' ? styles.DropdownCenter : styles.DropdownRight);

  return (
    <div
      data-testid="dropdown-menu"
      className={`${styles.MenuWrapper} ${showMenu} ${getOffset()} z-10`}
    >
      <div className="relative">
        <span className={styles.MenuArrow} />
      </div>
      <ul>
        {menuItems.map((item) => (
          <li key={item.text}>
            <div className="flex justify-center pr-3 w-9">
              <Image src={item.icon} alt={item.text} width={item.width} height={item.height} />
            </div>
            <Link href={item.link} passHref>
              <a target={item.target}>{item.text}</a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

AvatarDropdownMenu.defaultProps = {
  isVisible: false,
  offset: 'center',
};
