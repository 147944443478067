import Image from 'next/image';
import { useState } from 'react';
import { isIOS } from 'react-device-detect';
import ModalStatus from '../../const/modal';
import styles from '../../styles/SubscriptionModal.module.css';

interface SubscriptionModalProps {
  type: string;
}

const mobile = {
  ios: 'ios',
  android: 'android',
};

const SubscriptionModal = ({ type }: SubscriptionModalProps) => {
  const [expanded, setExpanded] = useState(isIOS && mobile.ios);
  const isIos = expanded === mobile.ios;
  const isAndroid = expanded === mobile.android;
  return (
    <div className={styles.subscriptionModal}>
      <h4>
        {type === ModalStatus.Cancel
          ? 'Cancel your mobile subscription'
          : 'Edit your mobile billing'}
      </h4>
      <div className={styles.appleSection}>
        <div
          role="button"
          tabIndex={0}
          className={styles.appleTitle}
          onClick={() => setExpanded(isIos ? '' : mobile.ios)}
          onKeyDown={() => setExpanded(isIos ? '' : mobile.ios)}
        >
          <h5>Cancel your Apple subscription</h5>
          <Image
            src={isIos ? '/icons/cross.svg' : '/icons/plus.svg'}
            width={isIos ? 14 : 8}
            height={isIos ? 14 : 8}
          />
        </div>
        {isIos ? (
          <div className={styles.appleBody}>
            1. Open the Settings app
            <br />
            2. Tap your name
            <br />
            3. Tap Subscriptions
            <br />
            4. Tap the SoleSavy subscription
            <br />
            5. Tap cancel subscription
          </div>
        ) : null}
      </div>
      <div className={styles.boundary} />
      <div className={styles.androidSection}>
        <div
          role="button"
          tabIndex={0}
          className={styles.androidTitle}
          onClick={() => setExpanded(isAndroid ? '' : mobile.android)}
          onKeyDown={() => setExpanded(isAndroid ? '' : mobile.android)}
        >
          <h5>Cancel your Android subscription</h5>
          <Image
            src={isAndroid ? '/icons/cross.svg' : '/icons/plus.svg'}
            width={isAndroid ? 14 : 8}
            height={isAndroid ? 14 : 8}
          />
        </div>
        {isAndroid ? (
          <div className={styles.androidBody}>
            1. Open the Google Play app
            <br />
            2. At the top right, tap the profile icon
            <br />
            3. Tap Payments & subscriptions {'->'} Subscriptions
            <br />
            4. Select the SoleSavy subscription
            <br />
            5. Tap cancel subscription
            <br />
            6. Follow the instructions
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubscriptionModal;
