import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { MenuItem, MenuOption } from '../interfaces/dropdown';
import styles from '../styles/DropdownMenu.module.css';

export interface DropDownMenuProps {
  menuItem: MenuItem;
  selectOption: Function;
  isShow?: boolean | undefined;
  showButton?: boolean;
}

const DropDownMenu = ({ menuItem, selectOption, isShow, showButton }: DropDownMenuProps) => {
  const handleOption = (option: MenuOption, index: number, name: string) => {
    selectOption(option, index, name);
  };
  const selected = showButton && menuItem?.options[menuItem?.selectedId as number];

  return (
    <Menu as="div" className="relative inline-block text-left w-full" data-testid="releaseFilter">
      {showButton ? (
        <div>
          <Menu.Button
            data-testid="menuButton"
            className="flex justify-between rounded-md border border-gray-300 px-3 py-1 bg-white text-base hover:bg-gray-50 w-40 h-8 transition duration-350 ease-in-out tracking-custom font-sfProTextRegular"
          >
            {selected && selected.label}
            <ChevronDownIcon className="h-5 w-5 -mr-2 mt-0.5" aria-hidden="true" />
          </Menu.Button>
        </div>
      ) : null}

      <Transition
        show={isShow}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={styles.menuItems}>
          <div className="py-1">
            {menuItem?.options?.map((option: any, index) => (
              <Fragment key={option.label}>
                {!option.value ? (
                  <div className="w-full text-gray-500 text-xs font-labBold px-4 border-t">
                    {option.label ? <div className="py-3">{option.label}</div> : <></>}
                  </div>
                ) : (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        role="button"
                        className={`${
                          active ? 'bg-gray-500 text-gray-900' : 'text-gray-700'
                        } block px-4 py-2 text-base`}
                        onClick={() => handleOption(option, index, menuItem?.name)}
                        onKeyPress={() => handleOption(option, index, menuItem?.name)}
                        tabIndex={0}
                        data-href={menuItem.name}
                      >
                        {option.label}
                      </div>
                    )}
                  </Menu.Item>
                )}
              </Fragment>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

DropDownMenu.defaultProps = {
  isShow: undefined,
  showButton: true,
};

export default DropDownMenu;
