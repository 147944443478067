import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { Price } from '../../interfaces/plan';
import { getCurrencySymbol } from '../../const/plan';
import { useApp } from '../../providers/App';
import ChargebeeAPIService from '../../services/Chargebee';
import styles from '../../styles/BasicWizardModal.module.css';
import { useUser } from '../../providers/Auth';

const chargebeeService: any = ChargebeeAPIService();
const pillColors = {
  pending: 'bg-warningLight text-warningDark',
  success: 'bg-successPure text-successDark',
};

const MembershipReview = ({ priceId, onChange }: { priceId: string; onChange: Function }) => {
  const [estimate, setEstimate] = useState<any>({});

  const { prices } = useApp();
  const user = useUser();
  const price = useMemo(
    () => (prices.find((_price: Price) => _price.id === priceId) || {}) as Price,
    [priceId, prices]
  );
  const renewDate = moment(estimate?.subscription_estimate?.next_billing_at * 1000);

  useEffect(() => {
    const promise = chargebeeService.estimate(priceId);
    promise.then((result: any) => {
      setEstimate(result.estimate || {});
    });
    return () => {
      if (promise?.abort) {
        promise.abort();
      }
    };
  }, [priceId]);

  const getPillClasses = (status: string) =>
    status === 'pending' ? pillColors.pending : pillColors.success;

  return (
    <div className="flex flex-col w-full p-6 gap-3 border rounded-lg">
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <span className="text-xl font-labBold text-black">
            {price.plan} {price.plan !== 'Community Plus' && 'membership'}
          </span>
          {!!user?.communityCode && (
            <span className="text-gray-700 text-sm font-sfProTextRegular">
              #{user?.communityCode}
            </span>
          )}
        </div>
        <span className="text-xl font-labBold text-black">
          {getCurrencySymbol(price.currency)}
          {price.amount?.toFixed(2)} {price.currency}
        </span>
      </div>
      <div className="flex w-full justify-between">
        <span className={`${styles.statusPill} ${getPillClasses('pending')}`}>pending</span>
        <span className="uppercase text-xxs font-labBold">
          billed {price.period === 'month' ? 'monthly' : 'annually'}
        </span>
      </div>
      <div className="flex w-full justify-between">
        <span className="text-sm">Renews on {renewDate.format('MMM D, YYYY')}</span>
        <a
          onClick={(e) => {
            e.preventDefault();
            onChange();
          }}
          href="#"
        >
          <span className={`${styles.changeYourPlan} text-sm underline`}>Change Your Plan</span>
        </a>
      </div>
    </div>
  );
};
export default MembershipReview;
