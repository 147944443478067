import { ReactElement, Fragment } from 'react';
import styles from '../../styles/OnboardingCard.module.css';
import { ListItemProps } from './ListItem';

export interface OnboardingCardStaticProps {
  stepId: string;
  title: string;
  description?: string;
}
export interface OnboardingCardContentProps extends Omit<OnboardingCardStaticProps, 'stepId'> {
  leftPanel: string;
  rightIcon?: ReactElement;
  rightButtons: ReactElement[];
  listItems?: ListItemProps[];
  body?: ReactElement;
}
interface OnboardingCardProps {
  onboardingCardContent: OnboardingCardContentProps;
}

const OnboardingCard = ({ onboardingCardContent }: OnboardingCardProps) => {
  const { leftPanel, rightIcon, title, description, rightButtons, body } = onboardingCardContent;
  return (
    <>
      <div className={`${styles.card} bg-gray-200 rounded flex justify-center items-center`}>
        <embed className="w-4/5" src={`/images/${leftPanel}.svg`} tabIndex={-1} />
      </div>
      <div className={`${styles.card} flex justify-center items-center`}>
        <div className={styles.rightCard}>
          {rightIcon}
          <h4
            data-testid="title"
            className={`font-tommyBold text-2xl font-medium ${description ? 'mt-8' : ''}`}
          >
            {title}
          </h4>
          {description ? (
            <p data-testid="description" className={styles.description}>
              {description}
            </p>
          ) : (
            <div className={styles.description}>{body}</div>
          )}
          <div className="flex items-center gap-4">
            {rightButtons.map((rightButton: ReactElement, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={i}>{rightButton}</Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default OnboardingCard;
