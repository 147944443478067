import { ReactNode } from 'react';
import styles from '../styles/SettingsLayout.module.css';

export const types = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
};

const fillColor: any = {
  info: '#0C4A6E',
  warning: '#78350F',
  error: '#8F0526',
  success: '#064E3B',
};

const blockColor: any = {
  info: 'bg-info text-info',
  warning: 'bg-warningLight text-warningDark',
  error: 'bg-chipInactive text-chipInactive',
  success: 'bg-chipActive text-chipActive',
};

const Message = ({
  type,
  className,
  children,
  ...props
}: {
  type?: string;
  className?: string;
  children: ReactNode | string;
}) => (
  <div className={`${styles.message} ${className}`} {...props}>
    <div className={`rounded-md p-3 gap-2 flex ${type} ${blockColor[type as string]}`}>
      <span className="pt-1">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.55544 1.34824C4.87103 0.469192 6.41775 0 8 0C10.121 0.00237154 12.1545 0.845988 13.6542 2.34577C15.154 3.84554 15.9976 5.87899 16 8C16 9.58225 15.5308 11.129 14.6518 12.4446C13.7727 13.7602 12.5233 14.7855 11.0615 15.391C9.59966 15.9965 7.99113 16.155 6.43928 15.8463C4.88743 15.5376 3.46197 14.7757 2.34315 13.6569C1.22433 12.538 0.462402 11.1126 0.15372 9.56072C-0.154961 8.00887 0.00346436 6.40034 0.608965 4.93853C1.21447 3.47672 2.23985 2.22729 3.55544 1.34824ZM8 3C8.55228 3 9 3.39465 9 3.88148V8.91852C9 9.40535 8.55228 9.8 8 9.8C7.44771 9.8 7 9.40535 7 8.91852L7 3.88148C7 3.39465 7.44771 3 8 3ZM8 13C7.77749 13 7.55999 12.936 7.37498 12.8161C7.18998 12.6962 7.04578 12.5257 6.96063 12.3263C6.87549 12.1269 6.85321 11.9075 6.89662 11.6958C6.94002 11.4841 7.04717 11.2896 7.2045 11.137C7.36184 10.9844 7.56229 10.8805 7.78052 10.8384C7.99875 10.7962 8.22495 10.8179 8.43052 10.9005C8.63608 10.9831 8.81179 11.1229 8.9354 11.3024C9.05902 11.4819 9.125 11.6928 9.125 11.9087C9.125 12.1981 9.00647 12.4757 8.79549 12.6804C8.58452 12.885 8.29837 13 8 13Z"
            fill={fillColor[type as string]}
          />
        </svg>
      </span>
      <p className="">{children}</p>
    </div>
  </div>
);
Message.defaultProps = {
  type: types.info,
  className: '',
};

export default Message;
