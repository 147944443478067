import { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Input from '@material-tailwind/react/Input';
import ChoiceField from './ChoiceField';
import { Genders, inputProps, inputRules } from '../../const/profile';
import styles from '../../styles/SettingsLayout.module.css';
import Error from './Error';

const name = 'gender';
const genderValues = Genders.map(({ value }) => value);

const GenderIdentityField = ({ form }: { form: UseFormReturn }) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = form;
  const gender = getValues(name);
  const [selfDescribed, setSelfDescribed] = useState(gender && !genderValues.includes(gender));
  return (
    <>
      <div className={styles.maleFemale}>
        {Genders.map((type) => (
          <ChoiceField
            form={form}
            name={name}
            value={type.value as string}
            onClick={() => setSelfDescribed(false)}
          >
            {type.label}
          </ChoiceField>
        ))}
      </div>
      <div className={styles.selfDescribe}>
        <ChoiceField
          form={form}
          name={name}
          value=""
          checked={selfDescribed}
          onClick={() => setSelfDescribed(true)}
        >
          I&apos;d like to self-describe
        </ChoiceField>
      </div>
      {selfDescribed && (
        <>
          <div className="flex items-center gap-1">
            <span className={styles.dividerLine} />
            <span className={styles.overLineText}>Self-Describe your Gender Identity</span>
            <span className={styles.dividerLine} />
          </div>
          <Controller
            control={control}
            rules={inputRules({ required: false })}
            name="gender"
            render={({ field: { value, ref, ...field } }) => (
              <div className={`${styles.socialSection} ${styles.field}`}>
                <Input
                  {...inputProps(field)}
                  defaultValue={value}
                  autoComplete="gender"
                  placeholder="Gender identity"
                  error={errors?.gender?.message && <Error>{errors?.gender?.message}</Error>}
                />
                <span className={styles.optional}>OPTIONAL</span>
              </div>
            )}
          />
        </>
      )}
    </>
  );
};

export default GenderIdentityField;
