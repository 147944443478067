import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styles from '../../styles/SettingsLayout.module.css';

const ChoiceField = ({
  form,
  name,
  value,
  children,
  onClick,
  checked,
  multiple,
  ...props
}: {
  form: UseFormReturn;
  name: string;
  value: string;
  children: ReactNode;
  checked?: boolean;
  onClick?: Function;
  multiple?: boolean;
}) => {
  const { setValue, getValues } = form;
  const currentValue = getValues(name);
  const on = checked !== undefined ? checked : currentValue === value;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={`${styles.choiceField} ${on ? styles.choiceSelected : ''} ${
        multiple ? styles.choiceCheckbox : styles.choiceRadio
      }`}
      key={value}
      {...props}
    >
      <input
        type={multiple ? 'checkbox' : 'radio'}
        name={name}
        value={value}
        checked={on}
        onClick={() => {
          if (!multiple) {
            setValue(name, value, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
          if (onClick) {
            onClick(value);
          }
        }}
      />
      {children}
    </label>
  );
};

ChoiceField.defaultProps = {
  onClick: null,
  checked: undefined,
  multiple: false,
};

export default ChoiceField;
