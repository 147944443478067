const InfiniteLoader = () => (
  <div className="text-center">
    <svg
      width="24"
      height="24"
      className="inline mr-2 w-7 h-7 text-gray-250 animate-spin"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9999 17.9986C16.4028 19.5957 14.3275 20.423 12.2349 20.4806C9.98578 20.5425 7.7167 19.7152 6.00013 17.9986C2.68649 14.685 2.68649 9.31253 6.00013 5.9989C9.31377 2.68526 14.6862 2.68526 17.9999 5.9989C19.4384 7.43741 20.2524 9.26392 20.442 11.1416C20.689 13.5891 19.875 16.1235 17.9999 17.9986Z"
        stroke="#86090B"
        strokeWidth="2"
      />
      <path
        d="M6.00026 18.001C7.71683 19.7175 9.9859 20.5449 12.2351 20.483C14.3276 20.4254 16.4029 19.598 18 18.001C19.8751 16.1258 20.6892 13.5914 20.4421 11.1439C20.2525 9.26624 19.4385 7.43973 18 6.00122"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </div>
);

export default InfiniteLoader;
