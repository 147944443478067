import Button from '../Button';
import OnboardingCard from '../Onboarding/OnboardingCard';
import styles from '../../styles/OnboardingCard.module.css';
import { useIsIapPlus } from '../../providers/Auth';
import { useApp } from '../../providers/App';
import ModalStatus from '../../const/modal';
import Message, { types as messageTypes } from '../Message';

const staticContent = {
  title: 'Unlock the power of Solesavy',
};

const bodyContent = [
  {
    icon: 'members',
    description: 'Members only community and marketplace',
  },
  {
    icon: 'conversation',
    description: 'Live training, drops and 1:1 support',
  },
  {
    icon: 'drops',
    description: 'Exclusive release and restock information',
  },
  {
    icon: 'bolt',
    description: 'Technology tools that help you cop manually faster',
  },
  {
    icon: 'launches',
    description: "Giveaways, coupons, sales and member's store access",
  },
];

export const CancelPlan = (props: any) => {
  const isIapPlus = useIsIapPlus();
  const { setGlobalModalStatus } = useApp();
  return isIapPlus ? (
    <Message type={messageTypes.warning} {...props}>
      <p className={styles.warningText}>
        We can&apos;t edit your Mobile Plus membership through our system, before upgrading learn
        how to&nbsp;
        <button type="button" onClick={() => setGlobalModalStatus(ModalStatus.Cancel)}>
          <u>cancel your plan.</u>
        </button>
      </p>
    </Message>
  ) : null;
};

export const UpgradePlanBody = () => (
  <>
    {bodyContent.map((content) => (
      <div key={`Upgrade ${content.icon}`} className="flex gap-2 my-1 items-center">
        <embed
          className={styles.bodyIcon}
          src={`/icons/${content.icon}.svg`}
          width={24}
          height={24}
          tabIndex={-1}
        />
        {content.description}
      </div>
    ))}
    <CancelPlan className="mt-4" />
  </>
);

const UpgradePlanComponent = () => {
  const { setGlobalModalStatus } = useApp();
  const isIapPlus = useIsIapPlus();
  const content = {
    ...staticContent,
    body: UpgradePlanBody(),
    leftPanel: 'upgrade-plan',
    rightButtons: isIapPlus
      ? []
      : [
          <div className="text-center" key="upgrade">
            <Button
              canActivate
              btnContext="upgrade"
              textSize="text-sm"
              handleClick={() => setGlobalModalStatus(ModalStatus.Upgrade)}
            />
          </div>,
        ],
  };
  return (
    <>
      <OnboardingCard onboardingCardContent={content} />
    </>
  );
};

const UpgradePlan = {
  component: UpgradePlanComponent,
  staticContent,
};

export default UpgradePlan;
