import { useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Input from '@material-tailwind/react/Input';
import { MenuOption } from '../../interfaces/dropdown';
import DropDownField from './DropDownField';
import Error from './Error';
import Countries, { OtherCountry, inputProps, inputRules } from '../../const/profile';
import styles from '../../styles/SettingsLayout.module.css';

const LocationField = ({
  form,
  showState,
  showCity,
  otherCountry,
}: {
  form: UseFormReturn;
  showState?: boolean;
  showCity?: boolean;
  otherCountry?: boolean;
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const locationCountry = getValues('locationCountry');
  const locationState = getValues('locationState');

  // states
  const defaultMenuState = useMemo(() => {
    const menuState = {
      locationCountry: {
        name: 'locationCountry',
        placeholder: 'Country',
        defaultSelectedOption:
          locationCountry && Countries.find(({ value }) => value === locationCountry),
        options: otherCountry ? Countries.concat([OtherCountry]) : Countries,
      },
    } as any;
    if (showState) {
      const provinces = Countries.find(({ value }) => value === locationCountry)?.options || [];
      menuState.locationState = {
        name: 'locationState',
        placeholder: 'State/Province',
        defaultSelectedOption:
          locationState && provinces.find(({ value }) => value === locationState),
        options: provinces,
      };
    }
    return menuState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [menuStates, setMenuStates] = useState(defaultMenuState);
  const [selfDescribed, setSelfDescribed] = useState(false);

  useEffect(() => {
    setSelfDescribed(locationCountry && !Countries.find(({ value }) => value === locationCountry));
  }, [locationCountry]);

  // update menuStates
  const handleMenu = (selectedOption: MenuOption | null, index: number, name: string) => {
    const newMenuStates: any = { ...menuStates };
    if (selectedOption && showState && name === 'locationCountry' && !Number.isNaN(index)) {
      newMenuStates.locationState.options = selectedOption?.options;
      setValue('locationState', '', {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('locationCity', '', {
        shouldValidate: true,
        shouldDirty: true,
      });
      setMenuStates(newMenuStates);
    }
  };

  return (
    <>
      {Object.keys(menuStates).map((name) => (
        <>
          {!!menuStates[name]?.options?.length && (
            <DropDownField {...menuStates[name]} form={form} selectOption={handleMenu} />
          )}
          {name === 'locationCountry' && otherCountry && selfDescribed && (
            <>
              <div className="flex items-center gap-1 -mt-4">
                <span className={styles.dividerLine} />
                <span className={styles.overLineText}>TELL US WHERE YOU LIVE</span>
                <span className={styles.dividerLine} />
              </div>
              <Controller
                control={control}
                rules={inputRules({ required: 'Country is required.' })}
                name="locationCustomCountry"
                render={({ field: { value, ref, ...field } }) => (
                  <div className={styles.field}>
                    <Input
                      {...inputProps(field)}
                      defaultValue=""
                      autoComplete="country"
                      placeholder="Country"
                      error={
                        errors?.locationCustomCountry?.message && (
                          <Error>{errors?.locationCustomCountry?.message}</Error>
                        )
                      }
                    />
                  </div>
                )}
              />
            </>
          )}
        </>
      ))}
      {showCity && (
        <Controller
          control={control}
          rules={inputRules({ required: 'City is required.' })}
          name="locationCity"
          render={({ field: { ref, ...field } }) => (
            <div className={styles.field}>
              <Input
                {...inputProps(field)}
                autoComplete="address-level2"
                placeholder="City"
                error={
                  errors?.locationCity?.message && <Error>{errors?.locationCity?.message}</Error>
                }
              />
            </div>
          )}
        />
      )}
    </>
  );
};

LocationField.defaultProps = {
  showState: false,
  showCity: false,
  otherCountry: false,
};

export default LocationField;
