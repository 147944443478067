import Head from 'next/head';

interface CustomHeadProps {
  pageName?: string;
  description?: string;
  inline?: boolean; // mostly used for testing
}
function CustomHead({ pageName, description, inline }: CustomHeadProps) {
  const tags = (
    <>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32.png" />
      <link rel="apple-touch-icon" href="/favicon-180.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/favicon-76.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/favicon-144.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon-180.png" />
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <title data-testid="title">SoleSavy | {pageName}</title>
      <meta name="robots" content="noindex" />
      <meta data-testid="description" name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=0.86"
      />
      <link rel="icon" href="/favicon.ico" />
    </>
  );
  return inline ? tags : <Head>{tags}</Head>;
}

CustomHead.defaultProps = {
  pageName: 'Something went wrong',
  description:
    'Access tools and resources to grow your collection, explore the education center, and manage your account.',
  inline: false,
};

export default CustomHead;
