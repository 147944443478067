import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import Navbar from './Navbar';
import Footer from './Footer';
import styles from '../styles/DashboardLayout.module.css';
import CustomHead from './CustomHead';
import BasicWizardModal from './BasicWizardModal';
import UpgradeYourPlanModal from './Upgrade/UpgradeYourPlanModal';
import { useAuth, useIsIapPlus } from '../providers/Auth';
import ModalStatus from '../const/modal';
import SubscriptionModal from './Upgrade/SubscriptionModal';
import Modal from './Modal';
import { useApp } from '../providers/App';
import LegacyPlusUpgradePlanModal from './Upgrade/LegacyPlusUpgradeModal';

interface LayoutProps {
  children: ReactNode;
  pageName: string;
  loading?: boolean;
}

const DashboardLayout = ({ children, pageName, loading }: LayoutProps) => {
  const { isAuthenticated } = useAuth();
  const isIapPlus = useIsIapPlus();
  const { globalModalStatus, setGlobalModalStatus } = useApp();
  const router = useRouter();
  const { state: checkoutState, modal } = router?.query || {};

  useEffect(() => {
    if (globalModalStatus === ModalStatus.Closed && modal === 'upgrade' && checkoutState) {
      setGlobalModalStatus(ModalStatus.Complete); // reloaded after upgrade, complete flow
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutState, globalModalStatus, modal]);

  return (
    <div id="dashboard-layout" className={`${styles.PageWrapper} overflow-auto`}>
      <div className="container flex flex-col h-screen">
        <Navbar />
        <CustomHead pageName={pageName} />
        {children}
        <section>
          <Footer />
        </section>
        {!loading && isAuthenticated && (
          <>
            {globalModalStatus !== ModalStatus.Closed &&
              (globalModalStatus === ModalStatus.Edit ||
              globalModalStatus === ModalStatus.Cancel ||
              (globalModalStatus === ModalStatus.Upgrade && isIapPlus) ? (
                <Modal
                  openModal={globalModalStatus !== ModalStatus.Closed}
                  handleClose={() => setGlobalModalStatus(ModalStatus.Closed)}
                >
                  {globalModalStatus === ModalStatus.Upgrade ? (
                    <LegacyPlusUpgradePlanModal />
                  ) : (
                    <SubscriptionModal type={globalModalStatus} />
                  )}
                </Modal>
              ) : (
                <UpgradeYourPlanModal />
              ))}
            <BasicWizardModal />
          </>
        )}
      </div>
    </div>
  );
};

DashboardLayout.defaultProps = {
  loading: false,
};

export default DashboardLayout;
