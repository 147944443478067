import { MouseEventHandler, ReactNode } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import ButtonLoader from './ButtonLoader';

interface ButtonProps {
  btnContext: string | ReactNode;
  leftIconName?: string;
  rightIconName?: string;
  iconSize?: number;
  canActivate?: boolean;
  href?: string;
  secondary?: boolean;
  border?: boolean;
  textSize?: string;
  textFont?: string;
  textCase?: string;
  padding?: string;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  target?: string;
  customClasses?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  title?: string;
  buttonLink?: boolean;
}

const ButtonStyle = {
  baseClasses:
    'appearance-none flex items-center justify-center rounded-full transition duration-350 ease-in-out',
  primaryButtonColor: 'text-white bg-primary active:bg-primaryActive hover:bg-red-700',
  secondaryButtonWithoutBorder: 'text-black bg-white hover:text-red-500',
  secondaryButtonWithBorder: 'text-black bg-white border border-gray-300 hover:border-red-500',
  defaultDisabledButtonColor: 'border-gray-300 text-gray-550 border cursor-not-allowed bg-gray-300',
  defaultTextFont: 'font-tommyBold',
  defaultTextCase: 'uppercase',
  defaultPadding: 'px-4 py-2', // TODO: ideally, this styling can now be done through customClasses prop but leaving it for now
};

export default function Button({
  btnContext,
  canActivate,
  leftIconName,
  rightIconName,
  iconSize,
  href = '',
  secondary,
  border,
  textSize,
  textFont,
  textCase,
  padding,
  handleClick,
  target,
  customClasses,
  type,
  title,
  buttonLink,
}: ButtonProps) {
  const router = useRouter();
  let buttonColor = ButtonStyle.primaryButtonColor;
  let onClick = handleClick;
  const textFontApplied = textFont || ButtonStyle.defaultTextFont;
  const textCaseApplied = textCase || ButtonStyle.defaultTextCase;
  const paddingApplied = padding || ButtonStyle.defaultPadding;
  if (canActivate) {
    if (!handleClick && href) {
      onClick = () => {
        if (target === '_blank') {
          window.open(href, '_blank');
        } else if (router?.push) {
          router.push(href);
        } else {
          window.location.href = href;
        }
      };
    }
    if (secondary && !border) {
      buttonColor = ButtonStyle.secondaryButtonWithoutBorder;
    } else if (secondary && border) {
      buttonColor = ButtonStyle.secondaryButtonWithBorder;
    }
  } else {
    buttonColor = ButtonStyle.defaultDisabledButtonColor;
  }

  return (
    <button
      className={`${buttonColor} ${textSize} ${textFontApplied} ${textCaseApplied} ${
        buttonLink ? 'p-0' : paddingApplied
      } ${ButtonStyle.baseClasses} ${customClasses}`}
      data-testid="button"
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={!canActivate}
      title={title}
      data-href={href}
    >
      {leftIconName ? (
        <div className="pr-2 flex items-center">
          {leftIconName === 'loading' ? (
            <ButtonLoader />
          ) : (
            <Image
              data-testid="left-icon"
              src={`/icons/${leftIconName}.svg`}
              width={iconSize}
              height={iconSize}
            />
          )}
        </div>
      ) : null}
      <span>{btnContext}</span>
      {rightIconName ? (
        <div className="pl-1 flex items-center">
          {rightIconName === 'loading' ? (
            <ButtonLoader />
          ) : (
            <Image
              data-testid="right-icon"
              src={`/icons/${rightIconName}.svg`}
              width={iconSize}
              height={iconSize}
            />
          )}
        </div>
      ) : null}
    </button>
  );
}

Button.defaultProps = {
  canActivate: false,
  leftIconName: '',
  rightIconName: '',
  iconSize: 24,
  href: '',
  secondary: false,
  border: false,
  textSize: '',
  textFont: '',
  textCase: '',
  padding: '',
  handleClick: undefined,
  target: '',
  customClasses: '',
  type: 'button',
  title: '',
  buttonLink: false,
};
export { ButtonStyle };
