import { useEffect, useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import Modal from './Modal';
import styles from '../styles/SettingsLayout.module.css';
import { useAuth, useIsBasic, useUser } from '../providers/Auth';
import Button from './Button';
import BirthdateField from './Field/BirthdateField';
import LocationField from './Field/LocationField';
import SneakerSizeField from './Field/SneakerSizeField';
import GenderIdentityField from './Field/GenderIdentityField';
import DropDownField from './Field/DropDownField';
import TopBrandsField from './Field/TopBrandsField';
import MembershipReview from './Field/MembershipReview';
import Error from './Field/Error';
import { joinReasons, stepCap } from '../const/profile';
import { getCurrencyForCountry, getFamilyForCountry, getTier } from '../const/plan';
import { MenuOption } from '../interfaces/dropdown';
import User from '../interfaces/user';
import ChargebeeAPIService from '../services/Chargebee';
import ChangeYourPlan from './ChangeYourPlan';
import { useApp } from '../providers/App';

const chargebeeService: any = ChargebeeAPIService();

const modalName = 'basic-wizard';

const modes = {
  closed: 0,
  basic: 1,
  checkout: 2,
  change: 3,
};

const BasicWizardModal = () => {
  const router = useRouter();
  const { saveUser, blockUser, checkToken } = useAuth();
  const isBasic = useIsBasic();
  const user = useUser();
  const { prices } = useApp();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const defaultValues = user || ({} as User);

  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState<number>(modes.closed);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { id: checkoutId, state: checkoutState, modal } = router?.query || {};
  const newUser = router?.query?.registration === 'success';

  const form: UseFormReturn = useForm<FieldValues>({
    mode: 'all',
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isValid },
  } = form;

  const [registeredPlan, basicWizardStep, locationCountry] = watch([
    'registeredPlan',
    'basicWizardStep',
    'locationCountry',
  ]);

  const steps = [
    {
      title: newUser ? 'Thanks, you’re in!' : 'Welcome back!',
      description: newUser
        ? 'Welcome to SoleSavy, tailor your experience by sharing more information about yourself.'
        : "Please confirm and update your account details to ensure you're getting the most out of your membership.",
      body: (
        <>
          <BirthdateField form={form} />
          <DropDownField
            form={form}
            name="joinReason"
            placeholder="Why are you joining SoleSavy?"
            defaultSelectedOption={
              (defaultValues.joinReason &&
                joinReasons.find(({ value }) => value === defaultValues.joinReason)) as
                | undefined
                | MenuOption
            }
            options={joinReasons}
          />
        </>
      ),
    },
    {
      title: 'How do you identify?',
      description:
        'SoleSavy is a diverse and inclusive community, help us connect you with sneakerheads.',
      body: (
        <>
          <GenderIdentityField form={form} />
          <p className="text-sm md:text-base text-center">
            Prefer not to share?&nbsp;
            <a
              href="#"
              className="underline text-red-500 hover:cursor-pointer"
              onClick={() => {
                setValue('gender', '');
                setValue('basicWizardStep', basicWizardStep + 1);
              }}
            >
              Skip step
            </a>
          </p>
        </>
      ),
    },
    {
      title: 'Where are you located?',
      description:
        'Hear about releases in your local time and connect with other members in your area.',
      body: <LocationField showState showCity form={form} />,
    },
    {
      title: 'What’s your sneaker size?',
      description:
        'Submit your size conversion preference and sneaker size for a tailored SoleSavy experience.',
      body: <SneakerSizeField form={form} showSizeTypes />,
    },
    {
      title: 'What are your top brands?',
      description:
        'Prioritize release information and news about the brands you’re most interested in.',
      body: <TopBrandsField form={form} />,
    },
  ];

  if (registeredPlan && isBasic) {
    steps.push({
      title: 'Review your membership',
      description:
        'Your subscription can be cancelled anytime, but will not end until the current billing cycle completes.',
      body: (
        <MembershipReview
          priceId={registeredPlan}
          onChange={() => !isLoading && setModalOpen(modes.change)}
        />
      ),
    });
  }

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (data.registeredPlan && data.basicWizardStep + 1 === steps.length) {
      saveUser(data);
      const result = await chargebeeService.hosted({ user, priceId: data.registeredPlan });
      if (result?.checkout) {
        sessionStorage.setItem('checkoutId', result?.checkout?.id);
        setCheckoutUrl(result.checkout.url);
        setModalOpen(modes.checkout);
      } else if (result?.error) {
        setErrorMsg(result.error.error_msg);
        // TODO: handle error better?
      }
      setIsLoading(false);
    } else {
      Object.assign(data, {
        basicWizardStep: Math.min(data.basicWizardStep + 1, steps.length),
      });
      const result = await saveUser({
        ...data,
        basicWizardStep: Math.min(data.basicWizardStep, stepCap),
        registeredPlan: data.basicWizardStep > stepCap ? '' : data.registeredPlan, // no longer needed after sub; also hides from reshowing
      });
      if (data.joinReason === joinReasons[2].value) {
        await blockUser();
        router.push({
          pathname: '/logout',
          query: {
            destination: '/unserviced?mode=reseller',
          },
        });
        return;
      }
      if (result?.name === 'Error') {
        setErrorMsg(result.message);
        // TODO: handle error better?
      }
      if (data.basicWizardStep >= steps.length) {
        if (data.registeredPlan) {
          setTimeout(() => {
            toast.success('Payment successful', {
              autoClose: 8000,
              onClose: () => checkToken(true), // try again in case FA hasn't been updated yet on first try
            });
            setValue('basicWizardStep', steps.length);
            sessionStorage.removeItem('checkoutId');
            checkToken(true); // force refresh token to pull updated permissions from FA
          }, 2000);
        } else {
          toast.success(newUser ? 'Account creation successful' : 'Account updates successful');
          setValue('basicWizardStep', data.basicWizardStep);
        }
      } else {
        setValue('basicWizardStep', data.basicWizardStep);
        reset(data);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (registeredPlan && locationCountry && prices?.length) {
      const currency = getCurrencyForCountry(locationCountry);
      const tier = getTier(registeredPlan) as string;
      const currentPrice = prices.find((_price) => _price.id === registeredPlan);
      const family =
        (user?.communityCode && currentPrice?.family) || getFamilyForCountry(locationCountry);
      const alternatePrice = prices.find(
        (_price) =>
          currency === _price.currency &&
          family === _price.family &&
          _price.period === currentPrice?.period &&
          _price.plan.toLowerCase().includes(tier)
      );

      if (alternatePrice?.id && currentPrice?.id !== alternatePrice.id) {
        setValue('registeredPlan', alternatePrice.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCountry, registeredPlan, prices, user?.communityCode]);

  useEffect(() => {
    setErrorMsg('');
    if (!modalOpen && basicWizardStep < steps.length) {
      setModalOpen(modes.basic);
      if (
        modal === modalName &&
        checkoutId === sessionStorage.getItem('checkoutId') &&
        checkoutState === 'succeeded'
      ) {
        // chargebee reloaded the page, which means payment was a success - finish up the wizard
        onSubmit({
          registeredPlan,
          basicWizardStep: steps.length,
        });
      }
    } else if (modalOpen && basicWizardStep < steps.length) {
      router.push({
        query: {
          ...router.query,
          modal: modalName,
          [modalName]: basicWizardStep,
        },
      });
    } else if (modalOpen && basicWizardStep >= steps.length) {
      const newQuery = {
        ...router.query,
        [modalName]: 'complete',
      } as any;
      delete newQuery.id;
      delete newQuery.state;
      delete newQuery.modal;
      router.push({
        query: newQuery,
      });
      setModalOpen(modes.closed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicWizardStep, checkoutId, checkoutState, modal, modalOpen, registeredPlan, steps.length]);

  const currentStep = steps[basicWizardStep];

  return (
    <Modal
      openModal={!!modalOpen}
      handleClose={() => setModalOpen(modes.closed)}
      fullScreen={modalOpen === modes.change}
      persistent={modalOpen === modes.basic}
      frameless={modalOpen === modes.checkout}
    >
      {modalOpen === modes.basic &&
        !!currentStep && ( // TODO: move to it's own component?
          <>
            <h1 className="text-2xl md:text-xxl font-tommyBlack text-gray-750">
              {currentStep.title}
            </h1>
            <form onSubmit={handleSubmit(onSubmit)} className="text-gray-600">
              <div className="max-w-centerMessage mx-auto pt-4 pb-8 text-sm md:text-base font-sfProTextRegular">
                {currentStep.description}
                {errorMsg && <Error>{errorMsg}</Error>}
              </div>
              <div className={styles.formSection}>{currentStep.body}</div>
              <div
                className={`flex ${
                  basicWizardStep === 0 ? 'justify-end' : 'justify-between'
                } items-center pt-4 pb-2`}
              >
                {!!basicWizardStep && (
                  <Button
                    secondary
                    canActivate={!isLoading}
                    handleClick={() => setValue('basicWizardStep', basicWizardStep - 1)}
                    leftIconName="chevron_left_red"
                    btnContext="go back"
                    iconSize={isMobile ? 12 : 16}
                    customClasses="focus:outline-none rounded-none text-xs md:text-base"
                    buttonLink
                  />
                )}
                <div className="flex gap-4 items-center">
                  <span className="font-sfProTextRegular text-xs md:text-base">
                    Step {basicWizardStep + 1} of {steps.length}
                  </span>
                  <Button
                    canActivate={!isLoading && isValid}
                    leftIconName={isLoading ? 'loading' : ''}
                    btnContext={
                      basicWizardStep + 1 < steps.length
                        ? 'continue'
                        : (registeredPlan && 'checkout') || 'get started'
                    }
                    rightIconName={isValid ? 'chevron_right_white' : 'chevron_right'}
                    iconSize={isMobile ? 12 : 16}
                    customClasses="text-xs md:text-base"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </>
        )}
      {modalOpen === modes.checkout && !!checkoutUrl && (
        <iframe title="checkout" src={checkoutUrl} height={600} width={400} />
      )}
      {modalOpen === modes.change && (
        <ChangeYourPlan
          isLoading={isLoading}
          priceId={registeredPlan}
          onChoose={(priceId: string, amount: number) => {
            if (!amount) {
              onSubmit({
                registeredPlan: '',
                basicWizardStep: steps.length,
              });
            } else {
              setValue('registeredPlan', priceId);
              setModalOpen(modes.closed);
            }
          }}
        />
      )}
    </Modal>
  );
};

export default BasicWizardModal;
